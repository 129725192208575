import { useIsOrderConfirmationPage } from './../../../utils/common/hooks/useIsOrderConfirmationPage';
import { useIsCheckoutPage } from '@olo-web/utils/common/hooks';
import { useGiftCardBalance } from '@domain';
import { calculateTipAmount } from '@olo-web/domain/orders/functions/calculateTips';
import { calculateOrderTotal } from '@olo-web/domain/orders/functions/calculateOrderTotal';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMemo } from 'react';
import { useCheckoutState } from '@olo-web/client-state';
import { useCurrentOrderTips } from '@olo-web/utils/common/hooks';
import { EPaymentMethods } from '@olo-web/types/enums/paymentMethods.enum';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';
import { useCheckedGuests } from '@olo-web/domain/orders/hooks/useCheckedGuests';
import { useTipAmount } from '@olo-web/domain/orders/hooks/useTipAmount';
import { useCalculateMixedPayment } from '@olo-web/components/templates/Checkout/common/hooks/useCalculateMixedPayment';
import { useIsOnPremisePaymentOnly } from '@olo-web/components/templates/Checkout/common/hooks/useIsOnPremisePaymentOnly';

type T = {
  isGooglePay: boolean;
  isApplePay: boolean;
  isCash: boolean;
  isCreditCard: boolean;
  isCreditCardOnTab: boolean;
  isMobilePayment: boolean;
  isZeroChargeOrder: boolean;
  tipAmount: number;
  orderTotal: number;
  giftCardCoversEntireOrderAmount: boolean;
  order: IOrder;
  giftCard: IGiftCard;
  isOrderLoading: boolean;
  isPayOnPremise: boolean;
};

export const usePaymentMethodValues = (): T => {
  const { selectedPaymentMethod: pM } = useCheckoutState();
  const { data: order, isLoading } = useOrder();
  const { data: giftCard } = useGiftCardBalance();
  const currentTipAmount = useTipAmount();
  const orderTips = useCurrentOrderTips();
  const isCheckoutPage = useIsCheckoutPage();
  const isOrderConfirmationPage = useIsOrderConfirmationPage();
  const { amount: ccAmount, tipAmount: ccTipAmount } = useCalculateMixedPayment();
  const checkedGuests = useCheckedGuests();
  const isSplitByAmount = useIsSplitByAmount();
  const GP = pM === EPaymentMethods.GOOGLE_PAY;
  const AP = pM === EPaymentMethods.APPLE_PAY;
  const cash = pM === EPaymentMethods.CASH;
  const CC = pM === EPaymentMethods.CREDIT_CARD;
  const cardOnTab = pM === EPaymentMethods.CARD_ON_TAB;

  const ticketTotal = useMemo(() => {
    if (isSplitByAmount) {
      return checkedGuests.total;
    } else {
      return +order?.preDiscountSubtotal;
    }
  }, [checkedGuests.total, isSplitByAmount, order?.preDiscountSubtotal]);

  const tipAmount = useMemo(() => {
    if (!order) return 0;

    const completedOrderTipAmount = order?.payments?.reduce(
      (prev, { tipAmount }) => prev + +tipAmount,
      0
    );

    if (isSplitByAmount || (isOrderConfirmationPage && !cash)) return +currentTipAmount;
    if (completedOrderTipAmount) return +completedOrderTipAmount;
    if ((isCheckoutPage || isOrderConfirmationPage) && cash) return 0;

    return calculateTipAmount(ticketTotal, orderTips);
  }, [
    order,
    isSplitByAmount,
    currentTipAmount,
    isCheckoutPage,
    isOrderConfirmationPage,
    cash,
    ticketTotal,
    orderTips,
  ]);

  const orderTotal = useMemo(() => {
    return calculateOrderTotal(order) + tipAmount;
  }, [order, tipAmount]);

  const giftCardCoversEntireOrderAmount: boolean = useMemo(() => {
    if (!giftCard) return false;

    return +ccAmount <= 0 && +ccTipAmount <= 0;
  }, [giftCard, ccAmount, ccTipAmount]);

  const isZeroChargeOrder = ticketTotal === 0 && tipAmount === 0;

  const isPayOnPremise = useIsOnPremisePaymentOnly();

  return {
    isGooglePay: GP,
    isApplePay: AP,
    isCash: cash,
    isCreditCard: CC,
    isCreditCardOnTab: cardOnTab,
    isMobilePayment: GP || AP,
    isZeroChargeOrder,
    tipAmount,
    orderTotal,
    giftCardCoversEntireOrderAmount,
    order,
    giftCard,
    isOrderLoading: isLoading,
    isPayOnPremise,
  };
};
