import { useMemo } from 'react';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { calculateTipAmount } from '@olo-web/domain/orders/functions/calculateTips';
import { useCheckoutState } from '@olo-web/client-state';
import { useCurrentOrderTips } from '@olo-web/utils/common/hooks';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';
import { useIsOrderConfirmationPage } from '@olo-web/utils/common/hooks/useIsOrderConfirmationPage';
import { useLastGuestPayment } from '@olo-web/domain/orders/hooks/useLastGuestPayment';

export const useTipAmount = (isGetFromFullAmount = false) => {
  const orderTips = useCurrentOrderTips();
  const checkoutState = useCheckoutState();
  const { data: order } = useOrder();
  const isSplitByAmount = useIsSplitByAmount();
  const isOrderConfirmationPage = useIsOrderConfirmationPage();
  const lastGuestPayment = useLastGuestPayment();

  const ticketTotal =
    isSplitByAmount && !isGetFromFullAmount
      ? +checkoutState.guestBalance
      : +order?.preDiscountSubtotal;

  return useMemo(() => {
    if (isOrderConfirmationPage) {
      if (isSplitByAmount) {
        return lastGuestPayment?.tipAmount;
      }

      return (
        order?.payments
          ?.reduce((tipSum, currentPayment) => tipSum + +currentPayment?.tipAmount, 0)
          .toFixed(2) || '0'
      );
    }

    return calculateTipAmount(ticketTotal, orderTips).toFixed(2);
  }, [orderTips, ticketTotal, isOrderConfirmationPage, order, isSplitByAmount, lastGuestPayment]);
};
